import React, { useState, useEffect, useContext } from 'react';
import './../../styles/bootstrap.css';
import './../../styles/App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import { BASE_API_URL,VENDOR_API_BASE_URL } from '../../constant';
import userContext from "../../context/userContext";
import { uploadImageToS3 } from '../../aws/awsUploader';
import moment from "moment";
import axios from "axios";
import img1 from "./food.jpg";
import {Link,useParams} from 'react-router-dom'

const VendorListingDetails = () => {
    const params = useParams();
    const { userData, setUserData } = useContext(userContext);
    const [service, setService] = useState([]);
    useEffect(() => {
        getServices();
        // eslint-disable-next-line
    }, []);


    //get services
    const getServices = () => {
        axios.get(`${VENDOR_API_BASE_URL}vendor-listing/edit/${params.vendorServiceId}`)
            .then(res => { console.log(res.data.results[0]);
                setService(res.data.results[0]);
            })
            .catch(err => { console.log(err) })
    }

    return (
        <div class="container">
            <div class="row">
                <h2 class="page-title my-3 ">Vendor Listing Details</h2>

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="border-bottom pb-2 ">
                        <div id="services" className="tab-pane active my-4">
                            <h2 className='heading'>Service / Sub Service</h2>

                            <div className="border-outer mb-4">
                                <div className="border-top px-4  py-3 ">

                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="ltitle">Title </label><br />
                                                <label for="title"><strong>{service.title}</strong></label>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="lcontact">Contact No.</label><br />
                                                <label for="contact"><strong>{service.phoneNumber}</strong></label>

                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="lservicetype">Service Type</label><br />
                                                <label for="servicetype"><strong> Venue (service)</strong></label>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="lagegroup">Age Group</label><br />
                                                <label for="agegroup"><strong>{service.ageGroup}</strong></label>

                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            &nbsp;
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="heading"> Capacity & Pricing </h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4  py-3 ">

                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="lcapacity">Minimum Capacity</label><br />
                                                <label for="capacity"><strong>{service.minCapacity}</strong></label>

                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <label for="lagegroup">Age Group</label><br />
                                            <label for="agegroup"><strong>{service.ageGroup}</strong></label>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <label for="lprice">Price</label><br />
                                            <label for="price"><strong>{service.price}</strong></label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">

                                            <label for="lmaxcapacity">Maximum Capacity</label><br />
                                            <label for="maxcapacity"><strong>{service.maxCapacity}</strong></label>


                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <label for="lminhours">Minimum Hours</label><br />
                                            <label for="minhours"><strong>2</strong></label>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <label for="lmaxhours">Maximum Hours</label><br />
                                            <label for="maxhours"><strong>5</strong></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="heading"> Special Pricing </h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4  py-3 ">

                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <label for="lstartdate">Start Date</label><br />
                                            <label for="startdate"><strong>12/04/2023</strong></label>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <label for="lenddate">End Date</label><br />
                                            <label for="enddate"><strong>30/04/2023</strong></label>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <label for="lrecurrence">Recurrence</label><br />
                                            <label for="recurrence"><strong>Custome</strong></label><br />
                                            <label for="recurrence"><strong>Fri, Sat & Sun</strong></label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <label for="lsmincapacity">Minimum Capacity</label><br />
                                            <label for="smincapacity"><strong>2</strong></label>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <label for="lpricetype">Price Type</label><br />
                                            <label for="pricetype"><strong>per Person</strong></label>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <label for="lprice">Price</label><br />
                                            <label for="price"><strong>200</strong></label>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <label for="lsmaxcapacity">Maximum Capacity</label><br />
                                            <label for="smaxcapacity"><strong>100</strong></label>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <label for="lsminperson">Minimum Persons</label><br />
                                            <label for="sminperson"><strong>10</strong></label>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <label for="lsmaxperson">Maximum Persons</label><br />
                                            <label for="smaxperson"><strong>200</strong></label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <h2 className="heading"> Location</h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4  py-3 ">

                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <label for="lcountry">Country</label><br />
                                            <label for="country"><strong>United Arab Emirates ,UAE</strong></label>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <label for="lstate">State</label><br />
                                            <label for="state"><strong>Dubai</strong></label>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <label for="laddress">Address</label><br />
                                            <label for="address"><strong>H-123, Dubai.</strong></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="heading ">About</h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4 py-3">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="labout">About</label><br />
                                                <label for="about"><strong>{service.otherInformation}</strong></label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="laboutdetails">About Description</label><br />
                                                <label for="aboutdetails"><strong>{service.description}</strong></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="ldelivery">Delivery Details</label><br />
                                                <label for="delivery"><strong>{service.deliveryDetail}</strong></label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="lrefund">Refund & Cancellation Policy</label><br />
                                                <label for="refund"><strong>{service.refundPolicy}</strong></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="heading ">Event & Features</h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4  py-3 ">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="levents">Events</label><br />
                                                { service.eventTemplate?
                                                service.eventTemplate.map((event,index)=>{
                                                     return <><label for="events1"><strong>{event.name}</strong></label><br /></>
                                                }):""
                                            }
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="lfeatures">Features</label><br />
                                                {service.selectedFeatures?service.selectedFeatures.map((feat,index)=>{
                                                    return <><label for="events1"><strong>{feat.featureName}</strong></label><br /></>
                                                }):''}
                                            </div>
                                        </div>
                                    </div>                                
                                    </div>
                            </div>
                            <h2 className="heading ">Operational Hours</h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4  py-3 ">
                                    <div className="row">
                                        <div className="col-md-2 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label for="lmonday">Monday</label><br /><br />
                                                <strong>Start Time<br />{moment(Number(service.monStart)).format("HH:mm")}</strong><br />
                                                <strong>End Time<br />{moment(Number(service.monEnd)).format("HH:mm")}</strong>
                                            </div>
                                        </div>
                                        <div className="col-md-2 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label for="ltuesday">Tuesday</label><br /><br />
                                                Start Time<br /><strong>{moment(Number(service.tueStart)).format("HH:mm")}</strong><br />
                                                End Time<br /><strong>{moment(Number(service.tueEnd)).format("HH:mm")}</strong>
                                            </div>
                                        </div>
                                        <div className="col-md-2 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label for="lwednesday">Wednesday</label><br /><br />
                                                Start Time<br /><strong>{moment(Number(service.wedStart)).format("HH:mm")}</strong><br />
                                                End Time<br /><strong>{moment(Number(service.wedEnd)).format("HH:mm")}</strong>
                                            </div>
                                        </div>
                                        <div className="col-md-2 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label for="lthursaday">Thursday</label><br /><br />
                                                Start Time<br /> <strong>{moment(Number(service.thuStart)).format("HH:mm")}</strong><br />
                                                End Time<br /><strong>{moment(Number(service.thuEnd)).format("HH:mm")}</strong>
                                            </div>
                                        </div>
                                        <div className="col-md-2 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label for="lfriday">Friday</label><br /><br />
                                                Start Time<br /> <strong>{moment(Number(service.friStart)).format("HH:mm")}</strong><br />
                                                End Time<br /><strong>{moment(Number(service.friEnd)).format("HH:mm")}</strong>
                                            </div>
                                        </div>
                                        <div className="col-md-2 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label for="lsaturday">Saturday</label><br /><br />
                                                Start Time<br /> <strong>{moment(Number(service.satStart)).format("HH:mm")}</strong><br />
                                                End Time<br /><strong>{moment(Number(service.satEnd)).format("HH:mm")}</strong>
                                            </div>
                                        </div>
                                        <div className="col-md-2 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label for="lsunday">Sunday</label><br /><br />
                                                Start Time<br /> <strong>{moment(Number(service.sunStart)).format("HH:mm")}</strong><br />
                                                End Time<br /><strong>{moment(Number(service.sunEnd)).format("HH:mm")}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="heading ">Gallery Information</h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4  py-3 ">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <input type="checkbox" id="yes" name="uae-residence" checked />&nbsp;<label for="lgallery">Gallery</label>
                                                <img src={service.coverImage} alt='img' width='100' />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <input type="checkbox" id="yes" name="uae-residence" value="yes" />&nbsp;<label for="lvideo">Video</label>
                                                <br/><a href={service.videoLinks} target="_blank">Link1</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="heading ">Linked Private Services</h2>
                            <div className="border-outer mb-4">

                                <div className="border-top px-4  py-3 ">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">

                                                <input type="checkbox" id="yes" name="uae-residence" checked />&nbsp;<label>Best Western Caterring
                                                </label><br />
                                                <input type="checkbox" id="yes" name="uae-residence" checked />&nbsp;<label>BW Decoration</label>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VendorListingDetails;