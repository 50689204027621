import React, { useState, useEffect, useContext } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import BackDrop from '@material-ui/core/Backdrop';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Paper from '@material-ui/core/Paper';
import EditText from '../../components/small/EditText';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddCircleTwoTone from '@material-ui/icons/AddCircleTwoTone';
import StanButton from '../../components/small/StanButton';
import userContext from "../../context/userContext";
import Chip from '@material-ui/core/Chip';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {BASE_API_URL} from '../../constant';
import Avatar from '@material-ui/core/Avatar';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { uploadImageToS3 } from '../../aws/awsUploader';
import { HexColorPicker } from "react-colorful";


const AddService = ({ classes, onClose, update }) => {
    const { userData, setUserData } = useContext(userContext);
    const [type1, settype1] = useState('Service');
    const [filters, setfilters] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [openFilters, setopenFilters] = useState(false);
    const [question, setquestion] = useState('');
    const [values, setvalues] = useState([""]);
    const [questions, setquestions] = useState([]);
    const [name, setname] = useState('');
    const [image, setImage] = useState('');
    const [bgImage, setBgImage] = useState('');
    const [bgColor, setBgColor] = useState('#FF0000');
    const [pickerOpen, setPickerOpen] = useState(false);



    let options = {
        method: "GET",
        headers: {
            Authorization: userData.token,
        },
    };
    const getFilters = async () => {
        const response = await fetch(
            `${BASE_API_URL}events-filters`,
            options
        );
        const data = response.json();
        data
            .then((data) => {
                if (response.status === 401) {
                    localStorage.removeItem("auth-token");
                    setUserData({
                        token: "",
                    });
                }

                if(update){
                    FilterListWhenUpdate(data.data.result)
                } else {
                    const d = data.data.result.map(v => {
                        return {
                            ...v,
                            isSelected: false,
                            isRequired: false
                        }
                    })
                 //   console.log(d)
                    setfilters(d);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const extractSlectedIds = () => {
        const selectedFilters = [];
        for (let filter of filters) {
            if (filter.isSelected) {
                selectedFilters.push(filter._id)
            }
        }
        return selectedFilters;
    }

    const fileChange = evt => { 
        uploadImageToS3(evt.target.files[0],'serviceImages')
            .then(res => {
                console.log({res})
                setImage(res.location)
            })
            .catch(err => { console.log(err) })
    }

    const bgImageChange = evt => {
        uploadImageToS3(evt.target.files[0],'serviceBgImages')
            .then(res => {
                console.log({res})
                setBgImage(res.location)
            })
            .catch(err => { console.log(err) })
    }
    // isSelected: false,
    // isRequired: false

    const FilterListWhenUpdate = (filters) => {
        const finalList = [];
        const fltrs = [...filters];
        const selectedFilters = [...update.filters];

        for(let fltr of fltrs){
            const found = selectedFilters.find(x => x._id === fltr._id);
            if(found) {
                finalList.push({...fltr, isSelected: true})
            } else {
                finalList.push({...fltr, isSelected: false})
            }
        }
        setfilters(finalList)
    }

    const resetForm = () => {
        setname('')
        setquestions([]);
        if (!update) {
            const filter = [...filters];
            filters.map(r => { return { ...r, isSelected: false } });
            setfilters(filter)
        }

    }

    const handleFinalSubmit_old = async () => {
        if (name.length === 0) {
            setError('Name should not be empty')
        } else {



           /* let formdata = new FormData();
            if (update) {
                formdata.append("serviceId", update._id);

            }
            const mappedQuestion = questions.map((q) => { return { ques: q.question, value: q.values } });
            formdata.append("name", name);
            formdata.append("image", image);
            formdata.append("type", type1);
            formdata.append("filters", JSON.stringify(extractSlectedIds()));
            formdata.append("questions", JSON.stringify(mappedQuestion));
            console.log({formdata})
   
                const response = await fetch(
                    `${BASE_API_URL}service-types`,
                    {
                        method: "POST",
                        headers: {
                            Authorization: userData.token,
                        },
                        body: formdata
                    }
                );
                const res = await response.json();
                if(response.status==409){
                    setError('Already exists')
                }else{
                    onClose()
                    //Reset All Form Data
                    resetForm()
                }
*/






                //start Jerry
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer "+userData?.token);
            const mappedQuestion = questions.map((q) => { return { ques: q.question, value: q.values } });
            let rawOBJ = {
                "name":name,
                "image":image,
                "bgImage":bgImage,
                "type":type1,
                "filters": extractSlectedIds(),//JSON.stringify(),
                "questions":mappedQuestion,//JSON.stringify(mappedQuestion),
                "bgColor":bgColor,
            }
            if (update) {
                rawOBJ.serviceId=update._id;
            }
            var raw = JSON.stringify(rawOBJ);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${BASE_API_URL}service-types`, requestOptions)
                .then(response => response.json())
                .then(response => {

                    console.log(response)

                    if(response.status==409){
                        setError('Already exists')
                    }else{
                        onClose()
                        //Reset All Form Data
                        resetForm()
                    }
                })
                .catch(error => console.log('error', error));
               
         
        }
    }

    const handleFinalSubmit = async () => {
        if (name.length === 0) {
            setError('Name should not be empty')
        } else {
                //start Jerry
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer "+userData?.token);
            const mappedQuestion = questions.map((q) => { return { ques: q.question, value: q.values } });
            let rawOBJ = {
                "name":name,
                "image":image,
                "bgImage":bgImage,
                "type":type1,
                "filters": extractSlectedIds(),//JSON.stringify(),
                "questions":mappedQuestion,//JSON.stringify(mappedQuestion),
                "bgColor":bgColor,
            }
            if (update) {
                rawOBJ.serviceId=update._id;
            }
            var raw = JSON.stringify(rawOBJ);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${BASE_API_URL}service-types`, requestOptions)
                .then(response => response.json())
                .then(response => {
                    if(response.status==409){
                        setError('Already exists')
                    }else{
                        onClose()
                        //Reset All Form Data
                        resetForm()
                    }
                })
                .catch(error => console.log('error', error));
        }
    }




    useEffect(() => {
        if (update) {
            setname(update.name);
            setBgColor(update?.bgColor)
            setImage(update?.image)
            setBgImage(update?.bgImage)
            if (update.questions) {
                let updateQuestion = [];
                for (let q of update.questions) {
                    updateQuestion.push({ question: q.ques, values: q.value })
                }
                setquestions(updateQuestion);
            }
        }
        getFilters()
    }, []);

    const handleFilters = i => {
        const filter = [...filters];
        filter[i].isSelected = !filter[i].isSelected
        setfilters(filter)
    }

    const handleremoveFilter = id => {
        const filter = [...filters];
        filter.splice(filters.findIndex(x => x._id === id), 1)
        setfilters(filter)
    }

    const handleIsRequired = id => {
        const filter = [...filters];
        filter[filters.findIndex(x => x._id === id)].isRequired = !filter[filters.findIndex(x => x._id === id)].isRequired
        setfilters(filter)
    }

    const handleValueChange = (value, index) => {
        const v = [...values];
        v[index] = value.target.value;
        setvalues(v);
    }

    const handleAddValue = () => {
        setvalues([...values, ""]);
    }

    const handleRemoveValue = i => {
        const v = [...values];
        v.splice(i, 1)
        setvalues(v);
    }

    const handleSubmitQuestion = () => {
        if (question.length === 0) {
            setError('question should not be empty')
        } else {
            for (let value of values) {
                if (value.length === 0) {
                    setError('question value should not be empty')
                    return
                }
            }
            setquestions([...questions, { question, values }])
            setquestion('')
            setvalues([""])
        }
    }

    const deleteQuestion = i => {
        const v = [...questions];
        v.splice(i, 1)
        setquestions(v);
    }

    return (
        <BackDrop open={true} className={classes.backdrop}>
            <Paper style={{ width: '60%', padding: 40, position: 'relative' }}>
                <Grid style={{ maxHeight: '70vh', overflow: 'auto' }}>
                    <Typography variant="h6">Add New Service</Typography>
                    <Grid container>
                        <Grid item xs={12} className={classes.container}>
                            <EditText value={name} onChange={e => setname(e.target.value)} rootStyle={{ width: '100%' }} label="Name" />
                            <Typography variant="subtitle2">Type</Typography>
                            <Grid container className={classes.buttonContainer}>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    onClick={e => settype1('Professional')}
                                    className={type1 === "Professional" ? classes.activeButton : ''}
                                > Professional </Button>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    onClick={e => settype1('Service')}
                                    className={type1 === "Service" ? classes.activeButton : ''}
                                >Service</Button>
                            </Grid>
                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }} >
                                <Typography>Filters</Typography>
                                <AddCircleTwoTone
                                    style={{ color: "#f47824", cursor: "pointer" }}
                                    onClick={e => setopenFilters(true)}
                                />
                            </Grid>

                            <Grid>
                                {filters.map((filter, i) => (
                                    filter.isSelected && (<StanButton isSelected={filter.isRequired} onClick={e => handleIsRequired(filter._id)} onCloseClick={e => handleremoveFilter(filter._id)} key={filter._id}>{filter.name}</StanButton>)
                                ))}
                            </Grid>
                            <Grid>
                            <label htmlFor="image">
                                <Avatar
                                    src={image}
                                    variant="rounded"
                                    style={{ width: 90, height: 100, cursor: 'pointer' }}>
                                    <CameraAltIcon />
                                </Avatar>
                                <input id="image" type="file" onChange={fileChange} style={{ display: 'none' }} />
                            </label>
                            </Grid>
                            <Grid>
                                <span>Background Icon</span><br/>
                            <label htmlFor="bgimage">
                                <Avatar
                                    src={bgImage}
                                    variant="rounded"
                                    style={{ width: 90, height: 100, cursor: 'pointer' }}>
                                    <CameraAltIcon />
                                </Avatar>
                                <input id="bgimage" type="file" onChange={bgImageChange} style={{ display: 'none' }} />
                            </label>
                            </Grid>
                        </Grid>

                        <Grid>
                            <Typography>Background Color</Typography>
                            <div style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'space-between',
                                padding:5,
                                minWidth:200,
                            }}>
                                <div style={{
                                    background:bgColor,
                                    color:'#FFFFFF',
                                    borderColor:'#000000',
                                    height:20,
                                    width:80,
                                    border:'1px solid'
                                }}>{bgColor}</div>
                                <span
                                    onClick={()=>setPickerOpen(!pickerOpen)}
                                    style={{
                                    textDecoration:'underline',
                                    cursor:'pointer',
                                }}>{pickerOpen ? 'Done': 'Pick Color'}</span>
                            </div>
                            <div style={{
                                display:pickerOpen ? 'block' : 'none',
                                marginTop:5,
                                marginBottom:15,
                            }}>
                                <HexColorPicker color={bgColor} onChange={setBgColor} />
                            </div>
                        </Grid>
                        <Grid item xs={1} />

                        {/* <Grid item xs>
                            <Grid container>
                                <Typography>Questions</Typography>
                            </Grid>
                            <Paper variant="outlined" style={{ padding: 20 }} className={classes.paper}>
                                <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 0 }}>
                                    <Typography>Add Question</Typography>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="secondary"
                                        disableElevation
                                        onClick={handleSubmitQuestion}
                                        style={{ color: '#FFF' }}
                                    >Save</Button>
                                </Grid>

                                <EditText label="Question" value={question} style={{marginTop: '20px'}} onChange={e => setquestion(e.target.value)} rootStyle={{ width: '100%' }} />
                                {values.map((value, i) => (
                                    <Grid container>
                                        <EditText
                                            deleteIcon={true}
                                            label={`Value ${i + 1}`}
                                            value={value}
                                            style={{marginTop: '20px'}}
                                            rootStyle={{ flexGrow: 1 }}
                                            onAdornClick={e => handleRemoveValue(i)}
                                            onChange={e => handleValueChange(e, i)} />

                                    </Grid>
                                ))}

                                <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {values[values.length - 1] !== "" && (
                                        <AddCircleTwoTone
                                            onClick={handleAddValue}
                                            style={{ color: "#f47824", cursor: "pointer" }}
                                        />
                                    )}
                                </Grid>

                            </Paper>


                            {questions.map((q, i) => (
                                <Paper key={`as${i}`} variant="outlined" style={{ padding: 16, marginBlock: 16, position: 'relative' }}>
                                    <Typography>Q. {q.question}</Typography>
                                    <ol style={{ marginLeft: 40 }}>
                                        {q.values.map((p, q) => (<li key={q}>{p}</li>))}
                                    </ol>
                                    <IconButton onClick={e => deleteQuestion(i)} style={{ position: 'absolute', right: 0, top: 0 }}>
                                        <DeleteOutlineIcon />
                                    </IconButton>

                                </Paper>
                            ))}

                        </Grid> */}
                    </Grid>


                    <IconButton onClick={e => { onClose(); resetForm(); }} className={classes.floatIcon}>
                        <ClearIcon fontSize="small" style={{ color: "#515151" }} />
                    </IconButton>

                </Grid>
                <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    onClick={handleFinalSubmit}
                    style={{ textTransform: 'none', width: 300 }}
                >{update ? "Update" : "Create"} Service</Button>
            </Paper>
            <BackDrop className={classes.backdrop2} open={openFilters}>
                <Paper variant="outlined" style={{ width: '50%', }}>
                    <Grid style={{ height: '60vh', overflow: 'auto', padding: 30 }}>
                        {filters.map((filter, i) => (
                            <Chip
                                key={filter._id}
                                color={filter.isSelected ? "primary" : "default"}
                                style={{ cursor: 'pointer', margin: 10 }}
                                onClick={e => handleFilters(i)}
                                label={filter.name} />
                        ))}
                    </Grid>
                    <Grid container justify="flex-end">
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            disableElevation
                            onClick={e => setopenFilters(false)}
                            style={{ color: '#FFF', textTransform: 'none', margin: '1rem' }}
                        >Ok</Button>
                    </Grid>


                </Paper>

            </BackDrop>

            <Snackbar open={error} autoHideDuration={3000} onClose={e => setError(null)}>
                <Alert severity="error">{error}</Alert>
            </Snackbar>
        </BackDrop>

    )
}


const styles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },

    backdrop2: {
        zIndex: theme.zIndex.drawer + 2,
    },

    floatIcon: {
        position: 'absolute',
        top: -10,
        right: -10,
        backgroundColor: 'lightGrey',
        width: '1.5rem',
        height: '1.5rem'
    },
    buttonContainer: {
        '&>*': {
            width: '50%',
            borderRadius: 0,
            textTransform: 'none'
        },

    },
    container: {
        '&>*': {
            marginTop: '0.5rem'
        }
    },

    activeButton: {
        border: '2px solid #f47824',
        color: '#f47824',
        backgroundColor: '#fff7f1'
    },
    paper: {
        marginTop: 10,
        '&>*': {
            marginTop: '0.7rem'
        },
    }

})

export default withStyles(styles)(AddService)