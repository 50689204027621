import React,{ useContext, useEffect, useState } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Button,
  lighten,
  Typography
} from "@material-ui/core";
import {Link} from 'react-router-dom'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import {BASE_API_URL} from '../../constant';
import { css } from "@emotion/core";
import { BounceLoader } from "react-spinners";
import userContext from "../../context/userContext";
import TablePagination from '@material-ui/core/TablePagination';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import './../../styles/cssformui.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

function EnhancedTableHead() {
  return (
    <TableHead>
      <StyledTableRow >
      <StyledTableCell style={{ fontWeight: 'bold'}}>ID</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Event Name</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Feedback Text</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Rating</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>By User</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Status</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Action</StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = ({ handleChange ,eventName,filterStatus,eventId,EventChange}) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        Testimonial Approval
      </Typography>
      <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-simple-select-helper-label">By Event</InputLabel>
          <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={eventId}
          onChange={EventChange}
        >
          {eventName.map(element => {
           return <MenuItem value={element._id}>{element.eventName} ({ element.sysEventId})</MenuItem>
          })}
        </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-simple-select-helper-label">By Status</InputLabel>
          <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filterStatus}
          //style={{ height: '37px' , width: '150px',marginRight:'20px'}}
          onChange={handleChange}
        >
          <MenuItem value={0}>All</MenuItem>
          <MenuItem value={false}>Pending</MenuItem>
          <MenuItem value={true}>Approved</MenuItem>
        </Select>
        </FormControl>
        </div>
    </Toolbar>
  );
};
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export default function TestimonialApproval({ classes }) {
  const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setsearch] = useState('');
    const { userData, setUserData } = useContext(userContext);
    const [count, setcount] = useState(0);
    const [create, setcreate] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [approv, setApprov] = React.useState(true); 
    const [eventName, setEvent] = React.useState([]);  
    const [status, setStatus] = React.useState(0);
    const [eventId, setEventId] = React.useState();
    
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const actionApprove = async (status,testimonailId) =>{
    let options = {
      method: "POST",
      headers: {
          Authorization: userData.token,
      },
      body: JSON.stringify({
        "testimonailId": testimonailId,
        "isApproved" : !status
      })
  };
  try
    {
      const response = await fetch(`${BASE_API_URL}approve-testimonial`,options);
      setApprov(!approv);
    }
  catch(err){
    alert("Error" + err)
  }
  }

  const actionDelete = async (testimonailId) => {
    confirmAlert({
        title: '',
        message: 'Do you want to delete this review',
        buttons: [
          {
            label: 'Yes',
            onClick: () => { 
                setLoading(true);
                let options = {
                  method: "POST",
                  headers: {
                      Authorization: userData.token,
                  },
                  body:  JSON.stringify({
                      "testimonailId": testimonailId,
                      "isDeleted" : true,
                      "isApproved": false
                  })
              };
                try
                  {
                    const response = fetch(`${BASE_API_URL}approve-testimonial`,options);
                    setApprov(!approv);
                  }
                catch(err){
                  alert("Error" + err)
                }
            }
          },
          {
            label: 'Cancel',
            onClick: () => {}
          }
        ]
      });
  }

  const getReviews = async () => {
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(
    `${BASE_API_URL}getTestimonial?skip=${page * rowsPerPage}&limit=${rowsPerPage}&search=${search}&status=${status}&eventId=${eventId}`, options);
    const data = response.json(); 
    
    data.then((data) => { console.log(data)
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }
      setRows(data.data.result);
      setcount(data.data.count);
      setLoading(false);
    })
      .catch((error) => console.log(error));
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const EventChange = (event) => { 
    setEventId(event.target.value);
  };
  

  const getEvents = async () => {
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(
    `${BASE_API_URL}get-booked-events?limit=900`, options);
    const data = response.json(); 
    
    data.then((data) => { 
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }
      setEvent(data.data.result);
    })
      .catch((error) => console.log(error));
  };



  useEffect(() => {
    getReviews();
    getEvents();
    // eslint-disable-next-line
  }, [search, create,rowsPerPage, page,approv,eventId,status]);

  return (
    <>
     {loading ? (
        <div
          style={{
            height: "28rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader
            color={" #f47824"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      ) : (
    <Paper style={{ margin: 10 }}>
    <EnhancedTableToolbar eventName={eventName} handleChange={handleChange} filterStatus={status} eventId={eventId} EventChange={EventChange} />
    <Divider />
<Divider />
    
    
    <TableContainer>
                <Table >
                  <EnhancedTableHead />
                  <TableBody>
                  {rows.map((row, index)=>{
                   return <StyledTableRow key={index}>
                      <StyledTableCell align='left'>{page*rowsPerPage+index+1}</StyledTableCell> 
                      <StyledTableCell align='left'>{row.eventId.eventName !==''?row.eventId.eventName:'NA'}</StyledTableCell> 
                      <StyledTableCell align='left' title={row.reviewText}>{row.reviewText.substring(0,100)} </StyledTableCell> 
                      <StyledTableCell align='left'>{row.rating}</StyledTableCell>
                      <StyledTableCell align='left'>{row.userId.firstName}</StyledTableCell>
                      <StyledTableCell align='left' onClick={()=>{ actionApprove(row.isApproved,row._id)}}>{row.isApproved ? <span style={{color:"green",cursor: "pointer"}}>Approved</span>: <span style={{color:"red",cursor: "pointer"}}>Pending</span> }</StyledTableCell>
                      <StyledTableCell align='left'><DeleteOutlineIcon onClick={()=>{ actionDelete(row._id)}}/></StyledTableCell>
                    </StyledTableRow> 
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
    </Paper>
      )}
    </>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600'
  },
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff'
  },
}));



const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#787878",
  },
  body: {
    fontSize: 14,
    padding: '0px 16px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    "&:nth-of-type(odd)": {
      backgroundColor: '#f8f8f8',
    },
  },
}))(TableRow);

const rootStyles = theme => ({
  btn: {
    border: '2px solid #f7cbb0',
    borderRadius: 2,
    backgroundColor: '#fef5f0',
    textAlign: 'center',
    padding: 3,
    fontWeight: 'bold',
    fontSize: 12,
    color: '#f87a25',
    marginRight: 6,
    marginTop: 3,
    marginBottom: 3
  },
  noMatch: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: " #f47824",
    textTransform: "uppercase"
  }
})