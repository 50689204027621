
import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
const DeleteDialog = ({ open,classes, onOkClick , onCancelClick, what ="service",isDeleted=false}) => {
    return (
        <Backdrop open={open} className={classes.backdrop}>
            <Paper style={{ width: '25%', padding: 20 }}>
                <Typography style={{marginBottom:'1.5rem'}}>Do you want to {isDeleted ? 'restore' : 'delete'} this {what} ?</Typography>
                <Grid style={{display:'flex', justifyContent: 'flex-end'}}>
                    <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        onClick={onOkClick}
                        style={{marginInline:16}}
                    > Ok </Button>

                    <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        onClick={onCancelClick}
                    > Cancel </Button>
                </Grid>
            </Paper>
        </Backdrop>
    );
}


const styles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
})

export default withStyles(styles)(DeleteDialog);

